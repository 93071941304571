
// lib
import { Mixins, Component, Prop, Watch } from 'vue-property-decorator'
import isEqual from 'lodash-es/isEqual'

// app
import Testimonial, { ITestimonial } from '@/app/article/editor/testimonial/shared/model'
import DataMixin from '@/app/shared/mixins/DataMixin'
import UiMediaUpload from '@/app/shared/components/ui-media-upload.vue'
import UiFormGroup from '@/app/shared/components/ui-form-group.vue'
import ConstructorComponent from '@/app/constructor/shared/models/ConstructorComponent'

const initialForm = (): Testimonial => new Testimonial({
  avatar: '',
  name: '',
  text: ''
})

@Component({
  components: {
    UiFormGroup,
    UiMediaUpload
  }
})
export default class AppArticleTestimonials extends Mixins<DataMixin<Testimonial>>(DataMixin) {
  @Prop({ type: ConstructorComponent }) readonly value!: ConstructorComponent

  form: Testimonial = initialForm()

  created (): void {
    this.dataMixin_setInitialForm(initialForm)
  }

  get listProp (): Testimonial[] {
    const list = this.value.attrs.list as ITestimonial[] | undefined

    if (!list) {
      return []
    }

    return list.map(v => new Testimonial(v))
  }

  @Watch('listProp', { immediate: true, deep: true })
  changeData (value: Testimonial[], valueOld: Testimonial[]): void {
    if (!isEqual(value, valueOld)) {
      this.dataMixin_data = value.map((v: Testimonial) => new Testimonial(v))
    }
  }

  @Watch('dataMixin_data')
  emitData (value: Testimonial[]): void {
    this.$emit('input', new ConstructorComponent({
      ...this.value,
      $uuid: this.value.$uuid,
      attrs: {
        list: value.map(v => new Testimonial(v))
      }
    }))
  }
}
