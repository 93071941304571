// app
import Model from '@/app/shared/models/Model'

export interface ITestimonial {
  avatar: string
  name: string
  text: string
}

export default class Testimonial extends Model<ITestimonial> implements ITestimonial {
  avatar = ''
  name = ''
  text = ''

  constructor (props?: ITestimonial) {
    super(props)

    Object.assign(this, props)
  }
}
